import React, { useEffect } from "react"
import Layout from "../../components/layout"
import TheFATable from "../../components/thefa-table"

export default function TeamBoysU10Colts() {
    
  return ( 
    <Layout>
    <section className="container">
    <h1>Under 10's - Colts</h1>
    <div className="row mb-2">
        <div className="col-md-8">
            <div className="row">
            <img src="/boys-u10-colts.jpg" className="img-fluid mb-4 team-page-hero" />
            </div>
            <div className="row mt-4 mb-4">
                <p>We train with the other two U10s teams and try to have a fun relaxed style working on the fundamentals of the game.</p>
                <p>
                    All coaches are FA qualified. In additional all staff are CRC with at least one First Aid qualified member present at all times.
                </p>
            </div>

            <div className="row border thefa-table g-0 rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 ">
            <h3>League Fixtures</h3>
            <TheFATable leagueId="784176923"></TheFATable>
            </div>

        </div>

        <div className="col-md-4">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <h3 className="lcj-color">Team Information</h3>
                <dl>
                <dt>Age Range</dt>
                <dd>School Year 5, DoB 01/09/2012 - 31/08/2013</dd>
                <dt>Manager(s)</dt>
                <dd>Chris Miall</dd>
                <dt>Training</dt>
                <dd>Saturday 9:30am on Bedquilts<br/>Friday 5:30pm on new Brownlee Centre 4g</dd>
                <dt>League</dt>
                <dd>Garforth Junior League</dd>
                <dt>Join the team</dt>
                <dd><a href="mailto:miall350@gmail.com">miall350@gmail.com</a></dd>
                </dl>
            </div>
            </div>

            <div className="row">
            <div className="col">
                <div className="card shadow-sm">
                <div className="card-header">Team Sponsor</div>
                <img src="/sponsor-slipstreamdesign.jpg" />
                <div className="card-body">
                    <p className="card-text">Slipstream Engineering Design Ltd is a Digital and Microwave RF design company providing engineering services to the Communication, Aerospace and Security industries.</p>
                    <div className="btn-group">
                    <a href="https://www.slipstream-design.co.uk/" target="_blank" className="btn btn-sm btn-outline-secondary">View Sponsor</a>
                    </div>
                </div>
                </div>
            </div>    
            </div>
        </div>
    </div>
    </section>
    </Layout>
  );
}

