import React, { useEffect } from "react"

export default function TheFATable(props) {

    const leagueId = props.leagueId;
    const elementId = 'lrep' + leagueId;

    useEffect(() => {
        const scriptCode = document.createElement('script');
        const scriptTag = document.createElement('script');

        scriptCode.type = 'text/javascript';
        scriptCode.async = true;
        scriptCode.innerHTML = `var lrcode = '${leagueId}';`;
      
        scriptTag.src = 'https://fulltime-league.thefa.com/client/api/cs1.js?v2';
        scriptTag.async = true;

        document.body.appendChild(scriptCode);
        document.body.appendChild(scriptTag);
      
        return () => {
            document.body.removeChild(scriptCode);
            document.body.removeChild(scriptTag);
        }
    })

    return (
        <div id={elementId}>Data loading....<a href="https://fulltime-league.thefa.com/Index.do?divisionseason=934320792">click here for Under 12 - Division 8</a><br/><br/><a href="http://www.thefa.com/FULL-TIME">FULL-TIME Home</a></div>

    );
}